import React, { useEffect, useState } from 'react';
import NavLink from './navlink';
import Image from 'next/image';
import easycampuslogo from "/public/images/easycampuslogo1.png";
import Link from 'next/link';
import Scrollup from '../scrollup/scrollup';

const navLinks = [
    {
        title: "Home",
        path: "/",
    },
    {
        title: "About Us",
        path: "/aboutus",
    },
    {
        title: "Solutions",
        path: "/solutions",
    },
    {
        title: "Blogs",
        path: "/blogs",
    },
    {
        title: "FAQ's",
        path: "/faqs",
    },
    {
        title: "Contact Us",
        path: "/contactus",
    },
];

const Navbar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const toggleNavbar = () => {
        setNavbarOpen(!navbarOpen);
    };

    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setSticky(window.scrollY > 50);
        });
    }, []);

    return (
        <nav className={`nav-container ${sticky ? 'dark-nav' : ''}`}>
          <Link href="/">
                <Image
                    src={easycampuslogo}
                    alt='interview skills'
                    height={300}
                    width={300}
                    className='logo h-[90px] lg:w-[260px] md:w-[170px] mb-1'
                />
            </Link>
            <div className=''>
                <ul className={navbarOpen ? '' : 'hide-mobile-menu'}>
                    {navLinks.map((link, index) => (
                        <li key={index}>
                            <NavLink href={link.path} title={link.title} target={link.target} />
                        </li>
                    ))}
                    
                    <li >
                        <Link href="https://app.easycampus.ai/" target='_blank' 
                            className={`text-[black] py-1 px-2 rounded border-[0.5px] border-[#41b5ee] hover:scale-95 text-sm sm:text-sm md:text-sm lg:text-base xl:text-base 2xl:text-base buttonshadowdarkblue `}>Login
                        </Link>
                    </li>
                    <li>
                        <Link href="https://app.easycampus.ai/registration" target="_blank" data-toggle="modal" data-target="#Easycampus">
                            <button className='bg-[#1e336a] text-[#fff] py-1 px-2 rounded hover:scale-95 text-sm sm:text-sm md:text-sm lg:text-base xl:text-base 2xl:text-base buttonshadow'
                               >
                                Register
                            </button>
                        </Link>
                    </li>
                </ul>
            </div>
            <button
                onClick={toggleNavbar}
                className='menu-icon'>
                {navbarOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
                        <path d="M6 18 18 6M6 6l12 12" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
                        <path d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                    </svg>
                )}
            </button>
            <Scrollup />
        </nav>
    );
};

export default Navbar;
