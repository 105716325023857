import React, { useEffect, useState } from "react";
import Link from "next/link";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // Check if consent is stored in localStorage
    const localConsent = localStorage.getItem("localConsent");
    if (localConsent === "true") {
      setShowConsent(false);
    } else {
      setShowConsent(true);
    }
    
    // console.log("Current localStorage:", localStorage);
  }, []);

  const acceptCookie = () => {
    // Save consent in localStorage
    localStorage.setItem("localConsent", "true");
    setShowConsent(false);
  };

  // Debugging: Log the local storage to the console

  if (!showConsent) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-slate-700 bg-opacity-70 z-[150]">
      <div className="fixed bottom-0 left-0 right-0 flex items-center justify-between px-6 py-6 bg-[#ffffffda] lg:mx-32">
        <div className="grid grid-cols-1 lg:grid-cols-12">
        <div className="col-span-1 lg:col-span-8 text-dark text-base">
          <h4 className="mb-2">We Value Your Privacy</h4>
        <p className="text-[#000] text-base font-[300]"> This website uses cookies to improve user experience.we'll asume you're ok with this, but you can opt out if you wish , you can view our
        <span className="text-[#1e336a] font-medium text-sm"> <Link href="">Privacy Policy</Link> or <Link href="">Terms And Conditions</Link> here.</span> </p> 
        </div>
        <div className="col-span-1 lg:col-span-4 lg:mt-0 mt-5 lg:flex justify-center items-center">
         <button className="bg-[#1e336a] mr-3 py-2 px-8 rounded text-white" onClick={acceptCookie}>
          Accept
        </button>
        <button className="bg-[#51a9ff] py-2 px-8 rounded text-white" onClick={acceptCookie}>
          Decline
        </button> 
        </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
