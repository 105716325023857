import RootLayout from '../components/rootlayout'; // Adjust the path as necessary
import Head from 'next/head';
import "../styles/globals.css"; // Adjust path as necessary
import { useEffect } from 'react';
import { SERVER_TYPE } from '@/utils/constant';
import CookieConsent from '@/components/cookies/cookieconsent';
import GTM from '@/components/gtm';
import { AnimatePresence } from 'framer-motion'
import Navbar from '@/components/topnavbar/navbar';
import Footer from '@/components/footer/footer';


function MyApp({ Component, pageProps }) {

  useEffect(() => {

    if (process.env.SERVER === SERVER_TYPE.PRODUCTION) {
      import('@fvilers/disable-react-devtools')
        .then((module) => {
          const disableReactDevTools = module.disableReactDevTools || module.default;
          if (typeof disableReactDevTools === 'function') {
            disableReactDevTools();
            console.log("disableReactDevTools function called");
          } else {
            console.log("disableReactDevTools is not a function");
          }
        })
        .catch((err) => {
          console.error("Failed to load disableReactDevTools:", err);
        });
    }

  }, []);

  const disableShortcuts = () => {
    useEffect(() => {
      // Disable right-click
      const handleContextMenu = (event) => {
        event.preventDefault();
      };
      document.addEventListener('contextmenu', handleContextMenu);

      // Disable certain keyboard shortcuts
      const handleKeyDown = (event) => {
        if (
          (event.ctrlKey && event.key === 'u') ||  // Ctrl+U
          (event.ctrlKey && event.key === 'U') ||  // Ctrl+U
          (event.ctrlKey && event.shiftKey && event.key === 'I') ||  // Ctrl+Shift+I
          (event.ctrlKey && event.shiftKey && event.key === 'i') ||  // Ctrl+Shift+i
          (event.key === 'F12') ||  // F12
          (event.ctrlKey && event.shiftKey && event.key === 'C') ||  // Ctrl+Shift+C
          (event.ctrlKey && event.shiftKey && event.key === 'J') ||  // Ctrl+Shift+J
          (event.ctrlKey && event.shiftKey && event.key === 'c') ||  // Ctrl+Shift+c
          (event.ctrlKey && event.shiftKey && event.key === 'j') ||  // Ctrl+Shift+j
          (event.ctrlKey && event.key === 's') ||  // Ctrl+S
          (event.ctrlKey && event.key === 'p') ||  // Ctrl+P
          (event.metaKey && event.key === 'u') ||  // Cmd+U (Mac)
          (event.metaKey && event.shiftKey && event.key === 'I') ||  // Cmd+Shift+I (Mac)
          (event.metaKey && event.shiftKey && event.key === 'C') ||  // Cmd+Shift+C (Mac)
          (event.metaKey && event.shiftKey && event.key === 'J') ||  // Cmd+Shift+J (Mac)
          (event.metaKey && event.key === 's') ||  // Cmd+S (Mac)
          (event.metaKey && event.key === 'p') ||  // Cmd+P (Mac)
          (event.altKey && event.key === 'u') ||  // Option+U (Mac)
          (event.altKey && event.shiftKey && event.key === 'I') ||  // Option+Shift+I (Mac)
          (event.altKey && event.shiftKey && event.key === 'C') ||  // Option+Shift+C (Mac)
          (event.altKey && event.shiftKey && event.key === 'J') ||  // Option+Shift+J (Mac)
          (event.altKey && event.key === 's') ||  // Option+S (Mac)
          (event.altKey && event.key === 'p')  // Option+P (Mac)

        ) {
          event.preventDefault();
        }
      };
      document.addEventListener('keydown', handleKeyDown);

      // Cleanup event listeners on component unmount
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);
  };

  if (process.env.SERVER === SERVER_TYPE.PRODUCTION) disableShortcuts();

  if (process.env.SERVER === SERVER_TYPE.PRODUCTION) {
    console.log = () => { };
    console.warn = () => { };
    console.error = () => { };
  }



  return (

    <RootLayout>
      <Head>
        <GTM gtmId={process.env.GMT_ID} />
        <title>Easy-Campus | Automate Campus Recruitment Drive</title>
        <meta name="description" content="EasyCampus ranked as #1 automated campus recruitment and online campus placement software, reduce your time of hiring, increase the quality of hiring and lower the cost of hiring." />
      </Head>
      <CookieConsent />
      <Navbar />
      <Component {...pageProps} />
      <Footer />
    </RootLayout>

  );
}

export default MyApp;
