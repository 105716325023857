import React,{ useEffect, useState } from 'react';

const Scrollup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (

    <button
      onClick={scrollToTop}
      id="myBtn"
      title="Go to top"
      className='bg-[#1e336a] rounded bottom-8 right-4  -translate-1/2 px-3 py-3 fixed z-10 text-[#fff]'
      style={{ display: isVisible ? 'block' : 'none',  filter: 'drop-shadow(0px 4px 4px rgba(255, 255, 255, 0.5))' }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  stroke="currentColor" className="w-6 h-6">
     <path  d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
     </svg>
    </button>
  );
};

export default Scrollup;
