import React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";

const  NavLink = ({href, title , target}) => {
  const currentPath = usePathname();
     
  return  (
    <>
     <Link href={href} target={target} 
        className={`block text-[black] text-lg sm:text-lg md:text-lg lg:text-[1.2rem] xl:text-[1.2rem] 2xl:text-[1.2rem] rounded hover:text-[#000] hover:font-medium nav-link nav-link-ltr ${currentPath ==  href ? "activenavonclick font-medium" : ""} `}> {title}
    </Link> 
    </>
  );
};

export default NavLink;